<template>
    <div>
        <b-sidebar id="add-new-patient-sidebar" :visible="changeStatusBool" bg-variant="white" sidebar-class="sidebar-lg"
            shadow backdrop no-header right @hidden="resetForm" @change="(val) => $emit('update:changeStatusBool', val)">
            <template #default="{ hide }">
                <!-- Header -->
                <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                    <h5 class="mb-0">
                        Changing status
                    </h5>

                    <i class="fas fa-times ml-1 cursor-pointer" size="16" @click="hide"></i>
                </div>

                <!-- BODY -->
                <div>
                    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
                        <!-- Form -->
                        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
                            <validation-provider #default="validationContext" name="Status" rules="required">
                                <b-form-group label-for="status">
                                    <label for="status">{{ $t("Status") }}</label>
                                    <treeselect v-model="typeVisit" :async="true" :load-options="searchTypeVisit"
                                        :defaultOptions="true" :normalizer="normalizer" />
                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>

                            <div class="d-flex mt-2">
                                <b-button variant="primary" class="mr-2" type="submit" :disabled="loading">
                                    <span v-if="!loading">{{ $t("Add") }}</span>
                                    <span v-else>
                                        <SpinnerLoading />
                                    </span>
                                </b-button>
                                <b-button type="button" variant="outline-danger" @click="hide">
                                    {{ $t("Cancel") }}
                                </b-button>
                            </div>
                        </b-form>
                    </validation-observer>
                    <hr />
                    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
                        <!-- Form -->
                        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmitOma)" @reset.prevent="resetForm">
                            <validation-provider #default="validationContext" name="Oma" rules="required">
                                <b-form-group label-for="Oma">
                                    <label for="Oma">Oma</label>
                                    <treeselect v-model="omaId" :async="true" :load-options="searchOma"
                                        :defaultOptions="true" :normalizer="normalizerOma" />
                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>

                            <div class="d-flex mt-2">
                                <b-button variant="primary" class="mr-2" type="submit" :disabled="loading">
                                    <span v-if="!loading">Add</span>
                                    <span v-else>
                                        <SpinnerLoading />
                                    </span>
                                </b-button>
                                <b-button type="button" variant="outline-danger" @click="hide">
                                    {{ $t("Cancel") }}
                                </b-button>
                            </div>
                        </b-form>
                    </validation-observer>
                </div>
            </template>
        </b-sidebar>
        <ToastNotification ref="toast" />
    </div>
</template>

<script>
import {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import SpinnerLoading from "@/components/SpinnerLoading";

import { required } from "@validations";

import ToastNotification from "@/components/ToastNotification";

import axios from "@/core/services/api/admin/consults/visitStatus";
import axiosU from "@/core/services/api/admin/user";

/* import ConsultAdd from "@/views/admin/type-consult/consult/ConsultAdd"; */
let clearTimeoutBuscador;

export default {
    components: {
        BFormTextarea,
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        ToastNotification,
        BButton,
        SpinnerLoading,

        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    model: {
        prop: "changeStatusBool",
        event: "update:changeStatusBool",
    },
    props: {
        changeStatusBool: {
            type: Boolean,
            default: false,
            required: false,
        },
        officeVisit: {
            type: Object,
            default: () => { },
            required: false,
        },
    },
    data() {
        return {
            required,
            typeVisit: null,
            optionsStatus: [],
            omaId: null,
            genderOptions: [
                { name: "Male", id: "male" },
                { name: "Female", id: "female" },
            ],
            normalizer(node) {
                return {
                    id: node.id,
                    label: node.name,
                };
            },
            normalizerOma(node) {
                return {
                    id: node.id,
                    label: `${node.name} ${node.lastname}`,
                };
            },
            loading: false,
        };
    },
    methods: {
        resetForm() {
            this.typeVisit = null
        },
        searchTypeVisit({ action, searchQuery, callback }) {
            if (action === "ASYNC_SEARCH") {
                const datos = {
                    name: searchQuery,
                    status: true,
                };
                clearTimeout(clearTimeoutBuscador);
                clearTimeoutBuscador = setTimeout(() => {
                    axios.visitStatusFilter(10, datos).then(({ data }) => {
                        callback(null, data);
                    });
                }, 400);
            }
        },
        searchOma({ action, searchQuery, callback }) {
            if (action === "ASYNC_SEARCH") {
                const datos = {
                    name: searchQuery,
                    role: 3,
                    status: true,
                };
                clearTimeout(clearTimeoutBuscador);
                clearTimeoutBuscador = setTimeout(() => {
                    axiosU.userFilter(10, datos).then(({ registro: { data } }) => {
                        callback(null, data);
                    });
                }, 400);
            }
        },
        async onSubmit() {
            this.loading = true;
            const datos = {
                visit_statuses_id: this.typeVisit,
            };
            await axios.changeStatusOfficeVisit(this.officeVisit.id, datos).then(({ status, message }) => {
                switch (status) {
                    case 'ok':
                        this.$emit("backdrop", { type: "success", message });
                        this.loading = false;
                        break;

                    default:
                        this.$emit("backdrop", { type: 'warning', message });
                        this.loading = false;
                        break;
                }
            }).catch(() => {
                this.loading = false;
                emit("backdrop", { type: 'danger', message: '' });
            });
        },
        async onSubmitOma() {
            this.loading = true;
            const datos = {
                oma_id: this.omaId,
            };
            
            await axios.changeOmaOfficeVisit(this.officeVisit.id, datos).then(({ status, message }) => {
                switch (status) {
                    case 'ok':
                        this.$emit("backdrop", { type: "success", message });
                        this.loading = false;
                        break;

                    default:
                        this.$emit("backdrop", { type: 'warning', message });
                        this.loading = false;
                        break;
                }
            }).catch(() => {
                this.loading = false;
                this.$emit("backdrop", { type: 'danger', message: '' });
            });
        }
    },
};
</script>

<style lang="scss" scoped>
.button-custom {
    padding: 5px !important;
}

.flatpickr-wrapper {
    width: 100% !important;
}
</style>
