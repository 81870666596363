<template>
  <div>
    <OfficeVisitFilter
      :loading="loading"
      @searchFilter="searchFilter"
      @filterPage="filterPage" />
    <b-card>
      <TitleTable titleTable="Office Visit" iconTable="fas fa-users" />
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <treeselect
              v-model="perPage"
              :options="perPageOptions"
              :clearable="true"
              class="w-25">
            </treeselect>
          </b-col>
          <!-- {{ $t("Search") }} -->
          <b-col cols="12" md="12">
            <div class="d-flex align-items-center justify-content-end">
              <GoBack />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        :items="items"
        :fields="tableColumns"
        hover
        bordered
        responsive
        primary-key="id"
        class="position-relative"
        show-empty
        empty-text="No matching records found">
        <template #cell(view)="data">
          <b-form-checkbox v-model="data.detailsShowing" @change="data.toggleDetails" />
        </template>

        <template #cell(show)="row">
          <b-form-checkbox
            v-model="row.detailsShowing"
            plain
            class="vs-checkbox-con"
            @change="row.toggleDetails">
          </b-form-checkbox>
        </template>

        <template #row-details="row">
          <b-card>
            <b-container>
              <b-row>
                <b-col cols="0">
                  <strong>Patient:</strong>
                </b-col>
                <b-col>
                  <span class="font-weight-bold d-block text-nowrap">
                    {{ row.item.patients.name }}
                    {{ row.item.patients.lastname }}
                  </span>
                  <small class="text-muted">{{ row.item.patients.email }}</small>
                </b-col>
                <b-col>
                  <strong>Visit Date : </strong>{{ row.item.visit_date }}
                  {{ row.item.lastname }}
                </b-col>
                <b-col>
                  <strong>Type Visit : </strong>
                  <span v-if="row.item.type_visits">
                    <span
                      :style="`text-decoration: underline ${row.item.type_visits.color}`"
                      >{{ row.item.type_visits.name }}</span
                    >
                  </span>
                  <span v-else>Not Found</span>
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col>
                  <b-row>
                    <b-row>
                      <b-card-group deck>
                        <b-card title="Visit Reason: ">
                          <span>
                            {{ row.item.reason_visit }}
                          </span>
                        </b-card>
                        <b-card title="Date Nurse: ">
                          <span v-if="row.item.office_visit.date_nurse">
                            {{ getTime(row.item.office_visit.date_nurse) }}
                          </span>
                        </b-card>
                        <b-card title="Date Oma: ">
                          <span v-if="row.item.office_visit.date_oma">
                            {{ getTime(row.item.office_visit.date_oma) }}
                          </span>
                        </b-card>
                        <b-card title="Date Procedure: ">
                          <span v-if="row.item.office_visit.date_procedure">
                            {{ getTime(row.item.office_visit.date_procedure) }}
                          </span>
                        </b-card>
                        <b-card title="Date Checkout: ">
                          <span v-if="row.item.office_visit.date_checkout">
                            {{ getTime(row.item.office_visit.date_checkout) }}
                          </span>
                        </b-card>
                      </b-card-group>
                    </b-row>
                    <b-col v-if="row.item.office_visit.visit_statuses_id == 5">
                      <b-card>
                        <b-row>
                          <b-col>
                            <b>Diagnostic Imagings: </b> <br />
                            <span
                              v-for="({ name }, key) in row.item.office_visit
                                .diagnostic_imagings"
                              :key="`${name}-${key}`">
                              - {{ name }}<br />
                            </span>
                          </b-col>
                          <b-col>
                            <b>Laboratories: </b> <br />
                            <span
                              v-for="({ name }, key) in row.item.office_visit
                                .laboratories"
                              :key="`${name}-${key}`">
                              - {{ name }}<br />
                            </span>
                          </b-col>
                          <b-col>
                            <b>Diagnosis: </b> <br />
                            <span
                              v-for="({ name }, key) in row.item.office_visit.diagnosis"
                              :key="`${name}-${key}`">
                              {{ name }}
                            </span>
                          </b-col>
                          <b-col>
                            <b>Procedures: </b> <br />
                            <span
                              v-for="({ name }, key) in row.item.office_visit.procedures"
                              :key="`${name}-${key}`">
                              - {{ name }}<br />
                            </span>
                          </b-col>
                          <b-col>
                            <b>Treatments: </b> <br />
                            <span
                              v-for="({ name, pivot: { cant } }, key) in row.item
                                .office_visit.treatments"
                              :key="`${name}-${key}`">
                              - {{ name }}: {{ cant }}<br />
                            </span>
                          </b-col>
                          <b-col>
                            <b>Tests: </b> <br />
                            <span
                              v-for="({ name }, key) in row.item.office_visit.tests"
                              :key="`${name}-${key}`">
                              - {{ name }}<br />
                            </span>
                          </b-col>
                          <b-col>
                            <b>Review of system: </b> <br />
                            <span
                              v-for="({ name }, key) in row.item.office_visit
                                .review_of_system"
                              :key="`${name}-${key}`">
                              - {{ name }}<br />
                            </span>
                          </b-col>
                        </b-row>
                      </b-card>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-container>
            <br />
            <b-button size="sm" variant="outline-secondary" @click="row.toggleDetails">
              Hide Details
            </b-button>
          </b-card>
        </template>

        <!-- Column: User -->
        <template #cell(patients)="data">
          <span>{{ data.item.patients.name }} {{ data.item.patients.lastname }}</span>
        </template>

        <template #cell(type_visits)="data">
          <div class="text-nowrap">
            <b-badge
              :style="{ 'background-color': data.item.type_visits.color }"
              v-b-tooltip.hover.top="data.item.type_visits.name"
              class="badge-glow text-white">
              <span class="align-text-top text-capitalize" style="color: black">{{
                data.item.type_visits.prefix
              }}</span>
            </b-badge>
          </div>
        </template>
        <template #cell(nurse)="data">
          <b-media v-if="data.item.office_visit.nurses" vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.office_visit.nurses.avatar"
                variant="light-primary" />
            </template>
            <b-link
              :to="{
                name: 'admin-users-view',
                params: { id: data.item.office_visit.nurses.id },
              }">
              <span
                class="font-weight-bold text-nowrap d-flex flex-column align-items-start">
                <span>
                  {{ data.item.office_visit.nurses.name }}
                </span>
                <span>
                  {{ data.item.office_visit.nurses.lastname }}
                </span>
              </span>
            </b-link>
          </b-media>
        </template>
        <template #cell(oma)="data">
          <b-media v-if="data.item.office_visit.omas" vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.office_visit.omas.avatar"
                variant="light-primary" />
            </template>
            <b-link
              :to="{
                name: 'admin-users-view',
                params: { id: data.item.office_visit.omas.id },
              }">
              <span
                class="font-weight-bold d-block text-nowrap d-flex flex-column align-items-start">
                <span>
                  {{ data.item.office_visit.omas.name }}
                </span>
                <span>
                  {{ data.item.office_visit.omas.lastname }}
                </span>
              </span>
            </b-link>
          </b-media>
        </template>

        <template #cell(status)="data">
          <div class="text-nowrap">
            <b-badge
              :style="{ 'background-color': data.item.office_visit.visit_statuses.color }"
              v-b-tooltip.hover.top="data.item.office_visit.visit_statuses.name"
              class="badge-glow cursor-pointer text-white"
              v-if="data.item.office_visit.visit_statuses">
              <span class="align-text-top text-capitalize" style="color: black">{{
                data.item.office_visit.visit_statuses.prefix
              }}</span>
            </b-badge>
          </div>
        </template>
        <template #cell(time)="data">
          <div class="text-nowrap">
            <span>{{ getTime(data.item.created_at) }}</span>
          </div>
        </template>

        <template #cell(actions)="data">
          <router-link
            v-if="
              $can('index', 'NurseProcedure') &&
              data.item.office_visit.visit_statuses_id >= 1 &&
              data.item.office_visit.visit_statuses_id < 3
            "
            :to="{ name: 'office-visit-view-nurse', params: { id: data.item.id } }">
            <i class="far fa-heart" v-b-tooltip.hover.top="`Nurse's View`"></i>
          </router-link>

          <router-link
            class="text-primary mx-2"
            v-if="
              $can('index', 'OmaProcedure') &&
              data.item.office_visit.visit_statuses_id >= 2 &&
              data.item.office_visit.visit_statuses_id < 4
            "
            :to="{ name: 'office-visit-view-oma', params: { id: data.item.id } }">
            <i
              class="fa fa-user-md"
              v-b-tooltip.hover.top="
                data.item.office_visit.visit_statuses_id === 3
                  ? 'Edit View'
                  : `View Visit`
              "></i>
          </router-link>

          <router-link
            v-if="
              $can('index', 'NurseProcedure') &&
              data.item.office_visit.visit_statuses_id === 3
            "
            :to="{ name: 'office-visit-recipe', params: { id: data.item.id } }"
            class="mr-1">
            <i class="far fa-file-alt" v-b-tooltip.hover.top="'Order View'"></i>
          </router-link>
          <i
            class="fa fa-random ml-1"
            style="cursor: pointer"
            v-b-tooltip.hover.top="'Change Status'"
            @click="changeStatusModal(data.item)"
            v-if="$can('index', 'admin')"></i>
        </template>

        <template #cell(is_membership)="row">
          <span>
            <i
              :class="
                row.item.is_membership
                  ? 'fa fa-star text-warning'
                  : 'fa fa-star text-muted'
              "></i>
          </span>
        </template>
      </b-table>
      <PaginationTable
        :dataMeta="dataMeta"
        :totalUsers="totalUsers"
        :perPage="perPage"
        :currentPage="currentPage"
        @page-changed="pageChanged" />
      <changeStatus
        :changeStatusBool.sync="changeStatusBool"
        :officeVisit="officeVisitData"
        @backdrop="statusChanged" />
    </b-card>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BTable,
  BFormCheckbox,
  BButton,
  BCard,
  BRow,
  BCol,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  VBTooltip,
} from 'bootstrap-vue'
import axiosCV from '@/core/services/api/office-visit/index'

import ToastNotification from '@/components/ToastNotification'
import PaginationTable from '@/components/PaginationTable'
import TitleTable from '@/components/TitleTable.vue'
import GoBack from '@/components/ButtonBack.vue'

import OfficeVisitFilter from './OfficeVisitFilter.vue'
import changeStatus from './changeStatus'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BBadge,
    BFormCheckbox,
    BAvatar,
    BMedia,
    BLink,
    BButton,
    OfficeVisitFilter,
    ToastNotification,
    PaginationTable,
    TitleTable,
    GoBack,
    changeStatus,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      officeVisitData: null,
      changeStatusBool: false,
      tableColumns: [
        {
          key: 'show',
          sortable: false,
          class: 'align-middle th-class-adjusted',
        },
        { key: 'id', sortable: true, class: 'align-middle th-class-adjusted' },
        {
          key: 'patients',
          label: 'Patient',
          sortable: true,
          class: 'th-class-adjusted align-middle',
        },
        {
          key: 'visit_date',
          label: 'Visit Date',
          sortable: true,
          class: 'th-class-adjusted align-middle',
        },
        {
          key: 'type_visits',
          label: 'Type Visit',
          sortable: true,
          class: 'th-class-adjusted align-middle',
        },
        {
          key: 'office_visit.room',
          label: 'Room',
          sortable: true,
          class: 'th-class-adjusted align-middle',
        },
        {
          key: 'nurse',
          label: 'Nurse',
          sortable: true,
          class: 'th-class-adjusted align-middle',
        },
        {
          key: 'oma',
          label: 'OMA',
          sortable: true,
          class: 'th-class-adjusted align-middle',
        },
        {
          key: 'status',
          label: 'Status',
          sortable: true,
          class: 'th-class-adjusted align-middle',
        },
        {
          key: 'time',
          label: 'Time',
          sortable: true,
          class: 'th-class-adjusted align-middle',
        },
        { key: 'actions', class: 'th-class-adjusted align-middle' },
        { key: 'actions' },
        { key: 'is_membership' },
      ],
      items: null,
      loading: false,
      dataMeta: { from: 1, to: 1, of: 1 },
      totalUsers: 0,
      perPage: 10,
      currentPage: {
        page: 1,
      },
      perPageOptions: [10, 25, 50, 100].map(i => ({
        id: i,
        label: `Show ${i} Entries`,
      })),
      filtro: false,
      userFilter: null,
      channel: null,
    }
  },
  created() {
    this.channel = this.$pusher.subscribe(
      `officeVisit.${JSON.parse(atob(localStorage.getItem('Facility')))}`
    )
    this.channel.bind('officeVisitEvent', async ({ event }) => {
      if (!this.filtro) {
        if (event.type === 'office_visiit' || event.type === 'appointment') {
          this.getUser()
        }
      } else {
        if (event.type === 'office_visiit' || event.type === 'appointment') {
          this.searchFilter(this.userFilter)
        }
      }
    })
    this.getUser()
  },
  destroyed() {
    this.$pusher.unsubscribe(this.channel)
  },
  watch: {
    perPage(newVal) {
      if (!this.filtro) {
        this.getUser()
      } else {
        this.searchFilter(this.userFilter)
      }
    },
  },
  methods: {
    statusChanged({ type, message }) {
      this.changeStatusBool = false
      this.$refs.toast[type](message)
      this.searchFilter()
    },
    changeStatusModal(officeVisit) {
      this.officeVisitData = officeVisit
      this.changeStatusBool = true
    },
    getUser() {
      axiosCV
        .officeVisitList(this.perPage)
        .then(({ data, current_page, total, per_page, ...res }) => {
          this.items = data
          this.totalUsers = total
          this.dataMeta.of = total
          this.dataMeta.from = res.from
          this.dataMeta.to = res.to
          this.currentPage.page = current_page
        })
    },
    pageChanged(page) {
      if (!this.filtro) {
        axiosCV
          .officeVisitListPagination(this.perPage, page, this.userFilter)
          .then(({ data, total, current_page, from, to }) => {
            this.items = data
            this.totalUsers = total
            this.dataMeta.of = total
            this.dataMeta.from = from
            this.dataMeta.to = to
            this.currentPage.page = current_page
          })
      } else {
        axiosCV
          .officeVisitFilterPagination(this.perPage, page, this.userFilter)
          .then(({ registro: { data, total, current_page, from, to }, registro }) => {
            this.items = data
            this.totalUsers = total
            this.dataMeta.of = total
            this.dataMeta.from = from
            this.dataMeta.to = to
            this.currentPage.page = current_page
          })
      }
    },
    searchFilter(value) {
      if (this.filtro) {
        this.loading = true
        this.userFilter = value
        const item = value
        item.type_visits_id = value.type_visits_id?.id
        item.user_type = value.user_type?.id
        axiosCV
          .officeVisitFilter(this.perPage, item)
          .then(({ registro: { data, total, current_page }, registro }) => {
            this.loading = false
            this.items = data
            this.totalUsers = total
            this.dataMeta.of = total
            this.dataMeta.from = registro.from
            this.dataMeta.to = registro.to
            this.currentPage.page = current_page
          })
          .catch(() => {
            this.loading = false
          })
      } else {
        this.getUser()
      }
    },
    filterPage(value) {
      this.filtro = value
    },
    changeStatus(item) {
      axiosCV
        .userUpdateState(item.id, { status: !item.status })
        .then(res => {
          this.$refs.toast.success('User status has been changed successfully')
        })
        .catch(err => {
          this.$refs.toast.danger('User status has not been changed')
        })

      this.pageChanged(this.currentPage.page)
    },
    isMedical(id) {
      const rolMain = JSON.parse(localStorage.getItem('userData')).roles[0]
      if (rolMain.id === 4) {
        const isAvalible = this.$ability.can('index', 'NurseProcedure')
        if (isAvalible) {
          return {
            name: 'office-visit-view-nurse',
            params: { id: id },
          }
        } else {
          return null
        }
      }
      const isAvalible = this.$ability.can('index', 'OmaProcedure')
      if (isAvalible) {
        return {
          name: 'office-visit-view-oma',
          params: { id: id },
        }
      } else return null
    },
    getTime(date) {
      // TODO: implementar como util
      const startDate = new Date(date)
      const now = new Date()

      let diff = now - startDate
      const segs = 1000
      const mins = segs * 60
      const hours = mins * 60
      const days = hours * 24
      const months = days * 30.416666666666668
      const years = months * 12

      //calculo
      const anos = Math.floor(diff / years)

      diff = diff - anos * years
      const meses = Math.floor(diff / months)

      diff = diff - meses * months
      const dias = Math.floor(diff / days)

      diff = diff - dias * days
      const horas = Math.floor(diff / hours)

      diff = diff - horas * hours
      const minutos = Math.floor(diff / mins)

      diff = diff - minutos * mins
      const segundos = Math.floor(diff / segs)

      return `${horas}:${minutos}:${segundos}`
    },
  },
}
</script>

<style></style>
